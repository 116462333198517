.cigarDetail {
  dl,
  p {
    margin: 0;
  }

  h3 {
    color: var(--primary-color);
    font-size: 1rem;
    margin: 0 0 0.5rem 0;
    padding: 0;
  }
}

.header {
  display: flex;
  justify-content: space-between;
}

.evenly {
  dl {
    flex: 1;
    white-space: nowrap;
  }
}

.row {
  margin-bottom: 0.5rem;

  :global(.p-tag) span:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.25rem;

    svg {
      margin-right: 0.25rem;
    }
  }
}

.image {
  margin-bottom: 1rem;
}

.description p {
  font-size: 0.85rem;
}

.purchases {
  font-size: 0.9rem;
}

.buttonbar {
  margin-bottom: 1rem;
}

.toolbar {
  margin-bottom: 1rem;
}

.rating {
  margin-bottom: 1rem;
}

.purchases > * {
  flex: 1;
}

.logNote {
  font-size: 85%;
  max-width: 12rem;
  line-height: 1rem;
  max-height: calc(1rem * 5);
  overflow: hidden;
}

.gifted {
  text-align: center;
}
